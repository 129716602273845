import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <section className="container">
          <p className="main-title">
              <a href="/">{siteTitle}</a>
          </p>
          <p className="main-colophon">Clarinet, flute &amp; saxophone</p>
      </section>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
