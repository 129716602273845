import React from "react"

const Footer = ({ siteTitle }) => (
    <footer className="container">
        <div className="row">
            <div className="columns six">
                <p className="social-links">
                    <a href="https://instagram.com/annieperrywoodwind"><img src="https://v3.pltcdn.net/prj_7owwjVa7g4xmKlfxP7EqPs/med_66AqaioKa8S5rgTIcqiXDO/5b6e780db76f47e2b83b457a68b54b5d/1464916878_instagram_circle_color.png" /></a>
                    <a href="https://twitter.com/anniegclarinet" style={{marginLeft: '4px'}}><img src="https://v3.pltcdn.net/prj_7owwjVa7g4xmKlfxP7EqPs/med_4FIowCOe0I5oyrJbekkEZZ/627672ca73e242829173a70d56a5b319/1464913785_twitter_circle_color.png" /></a>
                </p>
                <p><a href="mailto:annaperrymusic@gmail.com">annaperrymusic@gmail.com</a></p>
            </div>
            <div className="columns six clearfix">
                <p className="right" style={{marginTop: '9px'}}>© {new Date().getFullYear()} {siteTitle}</p>
                <p className="right">Designed by <a href="mailto:james@james-briggs.co.uk" target="_blank">James Briggs</a></p>
            </div>
        </div>
    </footer>
)

export default Footer;
